/* @tailwindcss base;
@tailwindcss components;
@tailwindcss utilities; */

.btn {
    border: none;
}

.swiper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center !important;
}

.slide {
    text-align: center;
    font-size: 28px;
    max-height: 90%;
}
/* 
.slider-control-bottomcenter {
  border-radius: 9999px;
  background: rgba(255, 255, 255, 1);
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -4rem;
  z-index: 50;
} */

.pagination {
    display: flex;
    list-style: none;
    gap: 10px;
}
.paging-item {
    width: 1rem;
    height: 1rem;
    background-color: rgb(148 163 184);
    border-radius: 100%;
}

.paging-item-active {
    background-color: #0d9488;
}

@media (prefers-color-scheme: dark) {
    .slider-control-bottomcenter {
        background: rgba(215, 215, 215, 1);
    }
    .paging-item-active {
        background-color: rgb(29 77 77 / var(--tw-bg-opacity));
    }
}
